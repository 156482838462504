<template>
	<div id="App">
		<test></test>
		<img src="./images/banner_02.png" style="width: 100%;" />
		<div class="cont">
			
		</div>
		<div class="suspension">
			<div class="su_left">
				<p>首页>人才招聘</p>
				<div class="su_fiex">
					<div class="c_l">
						<h4>{{detail.contentTitle}}<span>新</span></h4>
						<h1>1{{detail.contentKeyword}}</h1>
						<p>{{detail.contentDescription}}</p>
					</div>
					<div class="c_r">
						<p>发布时间：{{detail.updateDate}}</p>
					</div>
				</div>				

				<div class="c_text">
					<h2>个人信息</h2>
					<img src="./images/zp_18.png" />
					<ul>
						<li>
							<p>姓名<span>*</span></p>
							<input type="text" name="" id="" value="" v-model="name" />
						</li>
						<li>
							<p>手机号码<span>*</span></p>
							<input type="number" name="" id="" value="" v-model="mobile" />
						</li>
						<li>
							<p>邮箱</p>
							<input type="text" name="" id="" value="" v-model="email" />
						</li>
					</ul>
					<button @click="btn(detail.categoryId)">提交</button>
				</div>
			</div>
			<div class="su_right">
				<h5><p>最新职位</p><span>更多></span></h5>
				<ul>
					<li  v-for="item in list" @click="details(item.id)"><span>新</span>{{item.contentTitle}}</li>
				</ul>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	components: {
		test,
		foot	
	},
  data(){
	  return{
		  detail:'',
		  list:[],
		  name:'',
		  email:'',
		  mobile:''
	  }
	  
  },
  created(){
	  var that = this
	  const couponId = this.$route.query.couponId
	  console.log(couponId)
	  this.$axios.get('/cms/content/get.do',{
			params:{            
				id:couponId
			}
			 
	  }).then(function (res) {
			console.log(res);
			that.detail = res.data.data
		 
	  })
	  that.lists()
  },
  methods:{
	  btn(id){//提交
		  var that = this
		  if(!that.name){
			  alert('请填写姓名')
			  return;
		  }
		  if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile))){
		  	alert('请输入正确手机号')
		  	return;
		  }
		  this.$axios.post('/cms/applyJob/officialWebsite',{jobId:id,name:this.name,mobile:this.mobile,email:this.email}).then(function (res) {
		  		console.log(res);
		  		if(res.data=='success'){
		  			alert('提交成功')
		  		}
		  	 
		  })
	  },
	  lists(){
	  		  var that = this
	  		  this.$axios.post('/cms/returnAll/officialWebsite').then(function (res) {
	  		  		console.log(res);
	  		  		that.list = res.data.categoryList[4].contentList
	  		  	 
	  		  })
	  },
	  details(id){
	  	window.console.log("查询成功",id);
	  	 // this.$router.push("/recruit?id=" + id);
	  	 this.$router.push({
	  	     path: '/recruit',
	  	     query: {
	  	       id: id,
	  	     }
	  
	  	})
	  },
  }
}
</script>

<style>
	button{
		background-color: #ea0029;
		color: #fff;
		padding: 10px 40px;
		border: none;
		border-radius: 5px;
		width: 150px;
		margin: 20px auto;
	}
	.c_text ul{
		overflow: hidden;
		margin-top: 20px;
	}
	.c_text ul li{
		float: left;
		width: 50%;
		margin-bottom: 30px;
		
	}
	.c_text ul li input{
		outline: none;
		border: 1px solid #ccc;
		padding: 6px;
	}
	.cont{
		height: 500px;
	}
	.su_right h5{
		display: flex;
		justify-content: center;
		text-align: left;
	}
	.su_right h5 p{
		flex: 3;
	}
	.su_right h5 span{
		flex: 1;
		font-size: 12px;
		font-weight: normal;
		text-align: right;
	}
	.su_right ul{
		margin-top: 20px;
	}
	.su_right ul li{
		cursor:pointer;
		margin: 15px 0;
		font-size: 14px;
		color: #666666;
		text-align: left;
	}
	.su_right ul li span{
		background-color: #EA0029;
		color: #fff;
		padding: 3px;
		font-size: 10px;
		border-radius: 5px;
		margin-right: 8px;
	}
	.su_left>p{
		font-size: 12px;
		color: #666666;
		text-align: left;
		margin-bottom: 20px;
	}
	.c_text{
		margin-top: 6%;
		text-align: left;
	}
	.c_text p{
		font-size: 14px;
		margin-bottom: 10px;
	}
	.c_text p span{
		color: #ea0029;
	}
	.c_text input{
		border: 1px solid #f4f6f8;
	}
	.suspension{
		position: absolute;
		top: 20%;
		left:17.5%;
		/* transform: translateX(-50%); */
		width: 65%;
		max-width: 65%;
		display: flex;
		justify-content: center;
	}
	.su_left .c_r{
		flex: 4;
		text-align: right;
		
	}
	.su_left .c_r button{
		background-color: #EA0029;
		color: #fff;
		border: none;
		padding: 10px 20px;
		border-radius: 5px;
		font-size: 14px;
		margin-top: 15px;
	}
	.su_left .c_r p{
		margin-top: 10px;
		font-size: 12px;
		color:  #898383;
	}
	.su_left .c_l{
		flex: 6;
		text-align: left;
	}
	.su_left .c_l p{
		font-size: 13px;
		color: #898383
	}
	.su_left .c_l h4{
		font-weight: 500;
		font-size: 18px;
	}
	.su_left .c_l h1{
		color: #ea0029;
		margin: 10px 0;
	}
	.su_left .c_l h4 span{
		background-color: #ea0029;
		font-size: 14px;
		padding: 3px;
		color: #fff;
		border-radius: 5px;
		margin-left: 10px;
	}
	.su_fiex{
		margin-bottom: 12%;
		display: flex;
		justify-content: center;
	}
	.su_left{
		box-shadow: 4px 8px 15px #cccccc;
		background-color: #fff;
		flex: 7;
		padding: 3%;
		
	}
	.su_right{
		flex: 2;
		background-color: #fff;
		margin-left: 15px;
		padding: 2%;
	}
</style>
